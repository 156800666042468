<template>
	<div class="div">
		<!-- 选择日期 -->
		<div class="date">
			日期：
			<el-date-picker size="mini" v-model="date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
				@change="dateChange">
			</el-date-picker>
		</div>
		<div class="box">
			<div class="l" v-for="(item, index) in 4" :key="index">
				<div class="flex">
					品种：
					<el-select size="mini" v-model="names[index]" placeholder="请选择"
						@change="e => { getTsScreenshotLists(e, index) }">
						<el-option v-for="item in VarietiesLists" :key="item.name" :label="item.name"
							:value="item.name">
						</el-option>
					</el-select>
				</div>
				<div class="img">
					<img class="img" :src="imgs[index]" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'timeShare',
		data() {
			return {
				date: '',
				names: {
					0: '',
					1: '',
					2: '',
					3: ''
				},
				imgs: {
					0: '',
					1: '',
					2: '',
					3: ''
				},
				datas: [],
				VarietiesLists: []
			}
		},
		components: {},
		created() {
			this.getTsScreenshotVarietiesLists()
		},
		mounted() {
		},
		destroyed() {
			document.onkeydown = null
		},
		methods: {
			handleWatchEnter() {
				let that = this
				document.onkeydown = (e) => {
					//事件对象兼容
					let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
					//键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
					//左
					if (e1 && e1.keyCode == 40) {
						let nextDate = that.getNextDate(that.date, 1)
						that.dateChange(nextDate)
					} else if (e1 && e1.keyCode == 38) {
						let nextDate = that.getNextDate(that.date, -1)
						that.dateChange(nextDate)
					}
				}
			},
			getNextDate(date, day) {
				console.log(date, day);
				var dd = new Date(date);
				dd.setDate(dd.getDate() + day);
				var y = dd.getFullYear();
				var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
				var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
				return y + "-" + m + "-" + d;
			},
			dateChange(e) {
				this.date = e
				for (let i = 0; i < 4; i++) {
					this.getTsScreenshotLists(this.names[i], i)
				}

			},
			getTsScreenshotLists(e, i) {
				let that = this
				this.$axios
					.get('/index/getTsScreenshotLists', {
						params: {
							keywords: e,
							date: that.date
						}
					})
					.then(function(res) {
						if (res.data.data.list.length) {
							that.imgs[i] = res.data.data.list[0].image
						} else {
							that.imgs[i] = ''
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			getTsScreenshotVarietiesLists() {
				let that = this
				this.$axios
					.get('/index/getTsScreenshotVarietiesLists', {
						params: {}
					})
					.then(function(res) {
						that.VarietiesLists = res.data.data.list
						that.date = res.data.data.list[0].date
						that.handleWatchEnter()
						// res.data.data.list.push({
						// 	code: "测试",
						// 	id: 111,
						// 	name: '测试'
						// })
						for (let i = 0; i < 4; i++) {
							that.names[i] = res.data.data.list[0].name
						}
						for (let i = 0; i < 4; i++) {
							that.getTsScreenshotLists(res.data.data.list[0].name, i)
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.date {
		height: 30px;
	}

	.box {
		width: 100%;
		height: calc(100vh - 30px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		// flex-direction: column;
		flex-wrap: wrap;

		.flex {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 10px;
		}

		.l {
			width: 48%;
		}

		.img {
			width: 100%;
		}
	}
</style>