export let option = {
	tooltip: {
		trigger: 'axis'
	},
	grid: [
		{
			left: '10%',
			right: '8%',
			height: '50%'
		},
		{
			left: '10%',
			right: '8%',
			top: '70%',
			height: '25%'
		},
	],
	xAxis: [
		{
			type: 'category',
			// boundaryGap: false,
			data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月']
		},
		{
			type: 'category',
			gridIndex: 1,
			// boundaryGap: false,
			data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月']
		},
	],
	yAxis: [
		{
			type: 'value'
		},
		{
			gridIndex: 1,
			type: 'value'
		}
	],
	series: []
};