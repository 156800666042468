<template>
  <div class="about">
    
    <div class="flex">
        <el-button type="primary" @click="back">返回</el-button>
        <h1 style="text-align: center;">{{ this.date }}</h1>
    </div>
    <div class="mark_daily_line">
        <span>标记分时：</span>
        <el-switch v-model="mark_daily_line"></el-switch>
        <span v-if="mark_daily_line" style="margin-left: 10px;">单选：</span>
        <el-switch v-if="mark_daily_line" v-model="mark_daily_choose"></el-switch>
    </div>
    
    <div class="choose-item" v-if="!mark_daily_choose && moreClickItem.length">
    	<h6>已选列表</h6>
    	<el-tag size="mini" closable v-for="(item, index) in moreClickItem" :key="index" style="margin: 5px;" @close="tagclose(item, index)">
    		<!-- {{ item.date }} -->
    		{{ `${item.date.slice(0, 4)}-${item.date.slice(4, 6)}-${item.date.slice(6, 8)} ${item.time}` }}
    	</el-tag>
    </div>
    
    <div class="choose-box" v-if="!mark_daily_choose && moreClickItem.length">
    	<div class="item">
            类型：
            <el-select size="mini" v-model="submit_params.type" placeholder="请选择">
            	<el-option label="未标记" :value="0"></el-option>
            	<el-option label="空趋势下破均线到最低点" :value="1"></el-option>
            	<!-- <el-option label="多转空" :value="-1"></el-option> -->
            	<el-option label="空趋势上破均线到最高点" :value="2"></el-option>
            	<el-option label="多趋势下破均线到最低点" :value="3"></el-option>
            	<el-option label="多趋势上破均线到最高点" :value="4"></el-option>
            	<el-option label="震荡下破均线到最低点" :value="5"></el-option>
            	<el-option label="震荡上破均线到最高点" :value="6"></el-option>
            	<el-option label="夜盘开盘后下降到最低点反弹" :value="7"></el-option>
            	<el-option label="夜盘开盘后上升到最高点回调" :value="8"></el-option>
            	<el-option label="早盘开盘后下降到最低点反弹" :value="9"></el-option>
            	<el-option label="早盘开盘后上升到最高点回调" :value="10"></el-option>
            	<el-option label="午盘开盘后下降到最低点反弹" :value="11"></el-option>
            	<el-option label="午盘开盘后上升到最高点回调" :value="12"></el-option>
            </el-select>
        </div>
    	
    	<div class="item">
            操作：
            <el-select size="mini" v-model="submit_params.operation" placeholder="请选择">
            	<el-option label="不操作" :value="0"></el-option>
            	<el-option label="开多" :value="1"></el-option>
            	<el-option label="开空" :value="-1"></el-option>
            	<el-option label="多止盈" :value="2"></el-option>
            	<el-option label="多止损" :value="3"></el-option>
            	<el-option label="空止盈" :value="-2"></el-option>
            	<el-option label="空止损" :value="-3"></el-option>
            </el-select>
        </div>
        
        <div class="item">
            开始成本：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入开始成本"
            	v-model="submit_params.startprice"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            结束成本：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入结束成本"
            	v-model="submit_params.endprice"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            成本价差：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入成本价差"
            	v-model="submit_params.shortfall"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            开始时间：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入开始时间"
            	v-model="submit_params.starttime"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            结束时间：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入结束时间"
            	v-model="submit_params.endtime"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            区间时间：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入区间时间"
            	v-model="submit_params.sectiontime"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            开始持仓：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入开始持仓"
            	v-model="submit_params.startccl"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            结束持仓：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入结束持仓"
            	v-model="submit_params.endccl"
                style="width: 200px;">
            </el-input>
        </div>
        <div class="item">
            持仓差额：
            <el-input
                size="mini"
            	type="text"
            	placeholder="请输入持仓差额"
            	v-model="submit_params.chaccl"
                style="width: 200px;">
            </el-input>
        </div>
    	
    	<el-input
    		type="textarea"
    		:rows="1"
    		placeholder="请输入备注"
    		v-model="submit_params.marks"
    		style="width: 200px; margin-right: 10px;">
    	</el-input>
    	
    	<el-button size="mini" type="primary" @click="markline">确 定</el-button>
    </div>
    
    <div id="minute" style="width: 100%; height: 800px"></div>
    
    
    <el-dialog
    	:title="dialog_title"
    	:visible.sync="dialogVisible"
    	width="30%">
    	<div class="dialog-type">
    		<div class="item">
    			类型：
    			<el-select size="mini" v-model="submit_params.type" placeholder="请选择">
    				<el-option label="未标记" :value="0"></el-option>
    				<el-option label="空趋势下破均线到最低点" :value="1"></el-option>
    				<!-- <el-option label="多转空" :value="-1"></el-option> -->
    				<el-option label="空趋势上破均线到最高点" :value="2"></el-option>
    				<el-option label="多趋势下破均线到最低点" :value="3"></el-option>
    				<el-option label="多趋势上破均线到最高点" :value="4"></el-option>
    				<el-option label="震荡下破均线到最低点" :value="5"></el-option>
    				<el-option label="震荡上破均线到最高点" :value="6"></el-option>
    				<el-option label="夜盘开盘后下降到最低点反弹" :value="7"></el-option>
    				<el-option label="夜盘开盘后上升到最高点回调" :value="8"></el-option>
    				<el-option label="早盘开盘后下降到最低点反弹" :value="9"></el-option>
    				<el-option label="早盘开盘后上升到最高点回调" :value="10"></el-option>
    				<el-option label="午盘开盘后下降到最低点反弹" :value="11"></el-option>
    				<el-option label="午盘开盘后上升到最高点回调" :value="12"></el-option>
    			</el-select>
    		</div>
    		<div class="item">
    			操作：
    			<el-select size="mini" v-model="submit_params.operation" placeholder="请选择">
    				<el-option label="不操作" :value="0"></el-option>
    				<el-option label="开多" :value="1"></el-option>
    				<el-option label="开空" :value="-1"></el-option>
    				<el-option label="多止盈" :value="2"></el-option>
    				<el-option label="多止损" :value="3"></el-option>
    				<el-option label="空止盈" :value="-2"></el-option>
    				<el-option label="空止损" :value="-3"></el-option>
    			</el-select>
    		</div>
            
            <div class="item">
                开始成本：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入开始成本"
                	v-model="submit_params.startprice"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                结束成本：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入结束成本"
                	v-model="submit_params.endprice"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                成本价差：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入成本价差"
                	v-model="submit_params.shortfall"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                开始时间：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入开始时间"
                	v-model="submit_params.starttime"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                结束时间：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入结束时间"
                	v-model="submit_params.endtime"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                区间时间：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入区间时间"
                	v-model="submit_params.sectiontime"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                开始持仓：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入开始持仓"
                	v-model="submit_params.startccl"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                结束持仓：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入结束持仓"
                	v-model="submit_params.endccl"
                    style="width: 200px;">
                </el-input>
            </div>
            <div class="item">
                持仓差额：
                <el-input
                    size="mini"
                	type="text"
                	placeholder="请输入持仓差额"
                	v-model="submit_params.chaccl"
                    style="width: 200px;">
                </el-input>
            </div>
            
    		<div class="item">
    			<el-input
    				type="textarea"
    				:rows="1"
    				placeholder="请输入备注"
    				v-model="submit_params.marks">
    			</el-input>
    		</div>
    	</div>
    	<div slot="footer" class="dialog-footer">
    		<el-button size="mini" @click="dialogVisible = false">取 消</el-button>
    		<el-button size="mini" type="primary" @click="markline">确 定</el-button>
    	</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
            mark_daily_line: true, // true 标记日线
            mark_daily_choose: false, // true 单选
            dialogVisible: false,
            dialog_title: '',
            clickItem: {},
            submit_params: {
                tsid: '',
                type: 0,
                operation: 0,
                startprice: '',
                endprice: '',
                shortfall: '',
                starttime: '',
                endtime: '',
                sectiontime: '',
                startccl: '',
                endccl: '',
                chaccl: '',
                marks: ''
            },
            moreClickItem: [],
            // 
			data: [],
			tslineList: [],
			options: [],
			value: '',
			loading: null,
			date: ''
		};
	},
	components: {},
	created() {
		this.loading = this.$loading({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
		
		this.value = this.$route.query.value
		this.getTsline(this.$route.query.name)
		this.date = this.$route.query.name
		document.title = this.$route.query.name
	},
	mounted() {},
	methods: {
		varietieslist() {
			let that = this
			this.$axios
				.get('/index/varietieslist', {
					params: {
						keywords: '',
						limit: 999,
						page: 1
					}
				})
				.then(function(res) {
					that.options = res.data.data.list
					that.value = res.data.data.list[0].code
					that.getKline();
				})
				.catch(function(error) {
					console.log(error);
				});
		},
        tagclose(item, index) {
        	this.moreClickItem.splice(index, 1)
        },
        markline() {
        	let that = this
        	this.code = this.value
        	if (!this.mark_daily_choose) {
        		let ids = []
        		this.moreClickItem.forEach(v => {
        			ids.push(v.id)
        		})
        		this.submit_params.tsid = ids.join(',')
        	} else {
                this.submit_params.tsid = this.clickItem.id
            }
        	
        	this.$axios
        		.post('/index/marktscompose', this.submit_params)
        		.then(function(res) {
        			if (res.data.code == 1) {
        				that.$notify({
        					title: '成功',
        					message: res.data.msg,
        					type: 'success'
        				});
        				
        				if (!that.mark_daily_choose) {
        					that.moreClickItem = []
        				}
        				
                        
                        this.submit_params = {
                            type: 0,
                            operation: 0,
                            startprice: '',
                            endprice: '',
                            shortfall: '',
                            starttime: '',
                            endtime: '',
                            sectiontime: '',
                            startccl: '',
                            endccl: '',
                            chaccl: '',
                            marks: ''
                        }
        				that.dialogVisible = false
        			} else {
        				this.$notify.error({
        					title: '错误',
        					message: res.data.msg
        				});
        			}
        		})
        		.catch(function(error) {
        			console.log(error);
        		});
        },
		back() {
			this.$router.back()
		},
		getTsline(date) {
			let that = this;
			this.$axios
				.get('/index/tsline', {
					params: {
						code: this.value,
						date: date
					}
				})
				.then(function(res) {
					that.loading.close()
					that.tslineList = [...res.data.data.list[0], ...res.data.data.list[1]];
					that.drawChartTSline();
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		TSlineSplitData() {
			let categoryData = [];
			let price = [];
			let position = [];
			let volumes = [];
			let fsjj = [];
			let rawData = this.tslineList;

			for (let i = 0; i < rawData.length; i++) {
				categoryData.push(rawData[i].time);
				price.push(rawData[i].price);
				volumes.push(rawData[i].cjl_js);
				position.push(rawData[i].ccl);
				fsjj.push(rawData[i].fsjj);
			}
			
			// 计算成交量
			// for (let i = 0; i < position.length; i ++) {
			// 	let v = position[i]
			// 	console.log(`第${i}分钟：`)
			// 	if (i == 0) {
			// 		console.log('第一条跳过')
			// 	} else {
			// 		console.log(v, v - position[i - 1])
			// 		volumes[i] = Math.abs(v - position[i - 1])
			// 	}
			// }
			// console.log(volumes)
			
			return {
				categoryData,
				price,
				volumes,
				position,
				fsjj
			};
		},
		drawChartTSline() {
			let that = this
			// 基于准备好的dom，初始化echarts实例  这个和上面的main对应
			let myChart = this.$echarts.init(document.getElementById('minute'));
			// 指定图表的配置项和数据
			let data = this.TSlineSplitData();
			// console.log(data.categoryData);

			let option = {
				animation: false,
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross'
					},
					borderWidth: 1,
					borderColor: '#ccc',
					padding: 10,
					textStyle: {
						color: '#000'
					},
					position: function(pos, params, el, elRect, size) {
						const obj = {
							top: 10
						};
						obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
						return obj;
					},
					extraCssText: 'width: 200px;text-align: left;'
				},
				grid: [
					{
						left: '4%',
						right: '4%',
						height: '50%',
                        containLabel: true
					},
					{
						left: '4%',
						right: '4%',
						height: '30%',
						top: '65%',
                        containLabel: true
					}
				],
				toolbox: {
					feature: {
						dataZoom: {
							yAxisIndex: false
						},
						brush: {
							type: ['lineX', 'clear']
						}
					}
				},
				dataZoom: [
					{
						type: 'inside',
						xAxisIndex: [0, 1],
						start: 0,
						end: 100
					},
					{
						show: true,
						xAxisIndex: [0, 1],
						type: 'slider',
						top: '96%',
						start: 0,
						end: 100
					}
				],
				xAxis: [
					{
						type: 'category',
						data: data.categoryData,
						boundaryGap: false,
						axisLine: { onZero: false },
						splitLine: { show: false },
						min: 'dataMin',
						max: 'dataMax',
						axisPointer: {
							z: 100
						}
					},
					{
						type: 'category',
						gridIndex: 1,
						data: data.categoryData,
						boundaryGap: false,
						axisLine: { onZero: false },
						axisTick: { show: false },
						splitLine: { show: false },
						axisLabel: { show: false },
						min: 'dataMin',
						max: 'dataMax'
					}
				],
				yAxis: [
					{
						scale: true,
						splitArea: {
							show: true
						}
						// splitArea: {
						//   show: true
						// },
						// axisLabel: { show: false },
						// axisLine: { show: false },
						// axisTick: { show: false },
						// splitLine: { show: false }
					},
					{
						scale: true,
						splitArea: {
							show: true
						},
						gridIndex: 1,
						splitNumber: 4,
						// axisLabel: { show: false },
						// axisLine: { show: false },
						// axisTick: { show: false },
						// splitLine: { show: false }
						// min: function(value) { return value.min },
						// max: function(value) { return value.max }
					},
					{
						scale: true,
						splitArea: {
							show: true
						},
						gridIndex: 1,
						splitNumber: 4,
						splitLine: { show: false },
						// min: function(value) { return value.min },
						// max: function(value) { return value.max }
					}
				],
				axisPointer: {
					link: [
						{
							xAxisIndex: 'all'
						}
					],
					label: {
						backgroundColor: '#777'
					}
				},
				brush: {
					xAxisIndex: 'all',
					brushLink: 'all',
					outOfBrush: {
						colorAlpha: 0.1
					}
				},
				series: [
					{
						name: '价格',
						type: 'line',
						data: data.price,
						symbol: 'none',
						smooth: true,
						xAxisIndex: 0,
						yAxisIndex: 0,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: '分时均价',
						type: 'line',
						data: data.fsjj,
						symbol: 'none',
						smooth: true,
						xAxisIndex: 0,
						yAxisIndex: 0,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: '持仓量',
						type: 'line',
						data: data.position,
						symbol: 'none',
						smooth: true,
						xAxisIndex: 1,
						yAxisIndex: 2,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: '成交量',
						type: 'bar',
						data: data.volumes,
						symbol: 'none',
						smooth: true,
						xAxisIndex: 1,
						yAxisIndex: 1,
						lineStyle: {
							opacity: 0.5
						}
					}
				]
			};
            
            myChart.getZr().on('click', function(params) {
                // console.log(that.data, params)
                let pointInPixel = [params.offsetX, params.offsetY];
                
                let month = null
                let seriesObj = null
                if (myChart.containPixel('grid', pointInPixel)) {
                    let pointInGrid = myChart.convertFromPixel({
                        seriesIndex: 0
                    }, pointInPixel);
                    let xIndex = pointInGrid[0]; //索引
                    let handleIndex = Number(xIndex);
                    seriesObj = myChart.getOption(); //图表object对象
                    var op = myChart.getOption();
                    //获得图表中点击的列
                    month = op.xAxis[0].data[handleIndex];  //获取点击的列名
                }
                console.log(11, seriesObj)
                
                // 单选标记，直接点击k先
                if (that.mark_daily_choose) {
                	let clickItem = that.tslineList.find(v => {
                		return v.time == month.replace(/-/g, '')
                	})
                    console.log(clickItem)
                	that.dialogVisible = true
                	that.dialog_title = month
                	that.clickItem = clickItem
                } else {
                	let clickItem = that.tslineList.find(v => {
                		return v.time == month
                	})
                	
                    console.log(clickItem)
                    
                	// 判断存不存在
                	let item = that.moreClickItem.find(v => {
                		return v.id == clickItem.id
                	})
                	if (item) {
                		that.$notify.error({
                			title: '错误',
                			message: `${item.date}已添加！`
                		});
                	} else {
                		that.moreClickItem.push(clickItem)
                	}
                }
            })
            
            
            
            
			myChart.setOption(option);
		},
	}
};
</script>

<style lang="scss" scoped>
    .choose-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .item {
            margin: 5px;
        }
    }
    .dialog-type {
    	.item {
    		margin-top: 10px;
    	}
    }
	.about {
		.flex {
			padding: 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
</style>
