<template>
	<div>
		<div class="select-code">
			类型
			<el-select v-model="value2" filterable size="mini" placeholder="请选择">
				<el-option v-for="item in options2" :key="item.code" :label="item.name" :value="item.code"></el-option>
			</el-select>
			品种
			<el-select v-model="value" filterable size="mini" placeholder="请选择">
				<el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code"></el-option>
			</el-select>

			<el-button type="primary" size="mini" style="margin-left: 20px;">开始</el-button>
			<el-button type="primary" size="mini" style="margin-left: 20px;">停止</el-button>
		</div>

		<div class="kline">
			<div id="main"></div>
		</div>

		<div class="infos">
			<div class="title ">
				触发200次
			</div>
			<div class="title mt">
				提醒类型
			</div>
			<div class="tipslist">
				<div class="item">
					2023年01月01日 11:40 提醒：xxx
				</div>
				<div class="item">
					2023年01月01日 11:40 提醒：xxx
				</div>
			</div>

			<div class="title mt">
				图形识别
			</div>
			<div class="tipslist">
				<div class="item">
					2023年01月01日 11:40 图形识别：标记图：标记前置图：标记全图
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		option
	} from './b-echarts.js'

	export default {
		name: 'Backtesting',
		data() {
			return {
				// 品种/类型选择
				value: '',
				options: [],
				value2: 1,
				options2: [{
						name: '标记图形',
						code: 1
					},
					{
						name: '提醒任务',
						code: 2
					},
					{
						name: '开仓任务',
						code: 3
					}
				],
				kOption: option,
				setTime: null
			}
		},
		components: {},
		created() {
			this.varietieslist()
		},
		mounted() {
			this.draw()
		},
		methods: {
			draw() {
				const upColor = '#ec0000';
				const downColor = '#00da3c';


				const data0 = this.splitData([
					['2013/1/24', 2320.26, 1320.26, 2287.3, 2362.94],
					['2013/1/25', 2300, 1291.3, 2288.26, 2308.38],
					['2013/1/28', 2295.35, 1346.5, 2295.35, 2346.92],
					['2013/1/29', 2347.22, 1358.98, 2337.35, 2363.8],
					['2013/2/4', 2425.92, 1428.15, 2417.58, 2440.38],
					['2013/2/5', 2411, 1433.13, 2403.3, 2437.42],
					['2013/2/6', 1032.68, 1434.48, 2427.7, 2441.73],
				]);

				let myChart = this.$echarts.init(document.getElementById('main'));
				this.kOption.series = [{
						name: 'Dow-Jones index',
						type: 'candlestick',
						barWidth: 7,
						data: data0.values,
						itemStyle: {
							color: upColor,
							color0: downColor,
							borderColor: undefined,
							borderColor0: undefined,
						}
					},
					// {
					// 	data: [820, 932, 901, 934, 1290, 1330, 1320],
					// 	type: 'line',
					// 	areaStyle: {},
					// 	symbol: 'none',
					// 	markPoint: {
					// 		data: [{
					// 				value: '1',
					// 				label: {
					// 					show: true,
					// 					fontSize: 12,
					// 				},
					// 				coord: [0, 820],
					// 			},
					// 			{
					// 				value: '2',
					// 				label: {
					// 					show: true,
					// 					fontSize: 12,
					// 				},
					// 				coord: [5, 1330],
					// 			}
					// 		]
					// 	}
					// },
					{
						data: [820, 932, 901, 934, 1290, 1330, 1320],
						type: 'line',
						symbol: 'none',
						xAxisIndex: 1,
						yAxisIndex: 1,
					},
					// {
					// 	name: '成交量',
					// 	type: 'line',
					// 	data: [820, 932, 901, 934, 1290, 1330, 1320]
					// },
				]
				myChart.setOption(this.kOption);
				
				console.log(data0.values);
				
				this.setTime = setInterval(() => {
					this.kOption.xAxis[0].data.push('x月')
					this.kOption.xAxis[1].data.push('x月')

					this.kOption.series[0].data.push([1416.62, 2432.4, 2414.4, 2443.03])
					this.kOption.series[1].data.push(this.kOption.xAxis[0].data.length * 200)
					myChart.setOption(this.kOption);

					if (this.kOption.xAxis[0].data.length > 10) {
						clearInterval(this.setTime)
					}
				}, 1000)

			},
			splitData(rawData) {
				const categoryData = [];
				const values = [];
				for (var i = 0; i < rawData.length; i++) {
					categoryData.push(rawData[i].splice(0, 1)[0]);
					values.push(rawData[i]);
				}
				return {
					categoryData: categoryData,
					values: values
				};
			},
			varietieslist() {
				let that = this
				this.$axios
					.get('/index/varietieslist', {
						params: {
							keywords: '',
							limit: 999,
							page: 1
						}
					})
					.then(function(res) {
						that.options = res.data.data.list
						that.value = res.data.data.list[0].code
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.infos {
		width: 100%;
		padding: 20px;
		border: 1px solid #000;
		margin-top: 20px;
		box-sizing: border-box;

		.title {
			font-weight: bold;
		}

		.mt {
			margin-top: 20px;
		}
	}

	.kline {
		width: 100%;
		height: 600px;
		margin-top: 20px;

		#main {
			width: 100%;
			height: 100%;
		}
	}
</style>